export const seoFormatter = ({
  text,
  name,
  category,
  subcategory,
  manufacturer,
  price,
  annotate,
}) => {
  let value = text || '';

  value = value
    .replace(/{city}/gi, 'Москва')
    .replace(/{city_in}/gi, 'в Москве')
    .replace(/{email}/gi, process.env.DOMAIN)
    .replace(/{phone}/gi, 'номера пока нет')
    .replace(/{domain}/gi, process.env.EMAIL_COMPANY)
    .replace(/{category}/gi, category ? category : '')
    .replace(/{subcategory}/gi, subcategory ? subcategory : '')
    .replace(/{manufacturer}/gi, manufacturer ? manufacturer : '')
    .replace(/{price}/gi, price ? price : '')
    .replace(/{name}/gi, name ? name : '')
    .replace(/{annotate}/gi, annotate ? annotate : '');

  return value;
};

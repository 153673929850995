import React, { useState, useEffect, useContext } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cn from 'classnames';
import categories from '../categories.data';
import Button from '../../Button';
import routes, { routesPatterns } from '../../../helpers/routes';
import Emmiter from '../../../lib/emmiter';
import EVENTS from '../../../constants/EVENTS';
import NavigationDelivery from '../NavigationDelivery/NavigationDelivery';
import Input from '../../Input';
import useBodyOverflow from '../../../hooks/useBodyOverflow';
import styles from './NavigationMobile.module.scss';
import { AuthContext } from '../../../context/AuthContext';

const NavigationMobile = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { logout, authContext } = useContext(AuthContext);

  const inputValueDelete = () => {
    setInputValue('');
  };

  const handleLogout = (event) => {
    event.preventDefault();

    logout();
  };

  const inputChange = (e) => {
    setInputValue(e.target.value);
  };

  const router = useRouter();

  const handleSubmit = (event) => {
    event.preventDefault();

    router.push({
      pathname: routes.search(inputValue),
    });
  };

  const openModalFarmerRegistration = () => {
    Emmiter.emit(EVENTS.OPEN_MODAL_FARMER_REGISTRATION);
  };

  const closeMobileNav = () => {
    setShowMobileNav(false);
  };

  useEffect(() => {
    const toggleMobileNav = () => {
      setShowMobileNav(true);
    };

    Emmiter.on(EVENTS.OPEN_MOBILE_NAVIGATION, toggleMobileNav);

    return () => {
      Emmiter.off(EVENTS.OPEN_MOBILE_NAVIGATION, toggleMobileNav);
    };
  }, []);

  useBodyOverflow(showMobileNav);

  if (!showMobileNav) return null;

  return (
    <>
      <div className={styles.popupMenu} onClick={closeMobileNav}></div>{' '}
      <div className={styles.wrapper}>
        <div className={styles.deliveryPlaceBlock}>
          <NavigationDelivery></NavigationDelivery>
        </div>
        <div className={styles.mobileNavigation}>
          <div className={styles.header}>
            <Link className={styles.headerLogo} href="/">
              <img src="/icons2/header-logotip.svg" alt="logoCompany"></img>
            </Link>
            <Button className={styles.closeButton} variant="transparent" onClick={closeMobileNav}>
              <img alt="close" src="/icons2/close-mobile_navigation.svg"></img>
            </Button>
          </div>
          <div className={styles.inputBlock}>
            <form onSubmit={handleSubmit}>
              <Input
                className={styles.searchInput}
                value={inputValue}
                onChange={inputChange}
                simplePlaceholder="Поиск"
                search
                whiteBack
              ></Input>
            </form>

            {inputValue <= 0 ? (
              <Button variant="transparent" className={styles.searchButton}>
                <img src="/icons2/search-sm.svg" alt="search_icon"></img>
              </Button>
            ) : (
              <Button
                className={styles.clearInputValue}
                variant="transparent"
                onClick={inputValueDelete}
              >
                <img src="/icons2/close-UI_kit.svg" alt="close"></img>
              </Button>
            )}
          </div>

          <div className={styles.buttonContainer}>
            <Link href={routes.products} className={styles.catalogLink}>
              <Button className={styles.catalogButton} variant="transparent">
                <img alt="catalog" src="/icons2/icon_catalog-mobile_navigation.svg"></img>
                <span>Каталог</span>
              </Button>
            </Link>

            <div className={styles.promotionsDiscounts}>
              <Link href={routes.discounts}>
                <a
                  className={cn(styles.discountsButton, styles.promotionsDiscountsButton)}
                  variant="transparent"
                >
                  <img alt="discount" src="/icons2/percent, sales.svg"></img>
                  <span>Скидки</span>
                </a>
              </Link>
            </div>
          </div>

          <nav className={styles.menu}>
            <div className={styles.menuTitle}>Категории</div>
            <ul className={styles.menuList}>
              {categories.map((category) => (
                <li className={styles.menuItem} key={category.id}>
                  <Link
                    href={routesPatterns.productsCategory}
                    as={routes.productsCategory(category.slug)}
                  >
                    <Button
                      variant="transparent"
                      className={styles.menuItemButton}
                      onClick={closeMobileNav}
                    >
                      <span>{category.name}</span>
                      <img alt="jackDaw" src="/icons2/jackdaw_right-mobile_navigation.svg"></img>
                    </Button>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          <nav className={styles.menu}>
            <div className={styles.menuTitle}>Меню</div>
            <ul className={styles.menuList}>
              <li className={styles.menuItem}>
                <Link href={routes.farmers}>
                  <Button variant="transparent" className={styles.menuItemButton}>
                    <span>Наши фермеры</span>
                    <img alt="jackDaw" src="/icons2/jackdaw_right-mobile_navigation.svg"></img>
                  </Button>
                </Link>
              </li>
              <li className={cn(styles.menuItem, styles.lastMenuItem)}>
                <Button
                  variant="transparent"
                  className={styles.menuItemButton}
                  onClick={openModalFarmerRegistration}
                >
                  <span>Стать фермером</span>
                  <img alt="jackDaw" src="/icons2/jackdaw_right-mobile_navigation.svg"></img>
                </Button>
              </li>
            </ul>
          </nav>
          {authContext?.user && (
            <Button className={styles.logOutAccount} variant="transparent" onClick={handleLogout}>
              <img alt="logOut" src="/icons2/log_out-mobile_navigation.svg"></img>
              <span>Выйти из аккаунта</span>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default NavigationMobile;

import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import get from 'lodash/get';
import { useRouter } from 'next/router';
import routes from '../../helpers/routes';
import { seoFormatter } from '../../helpers/seoFormatter';

const SeoHead = (props) => {
  const router = useRouter();
  let productSchema;
  let productCatalog;
  const { seo } = props;
  const mainHost = process.env.NEXT_PUBLIC_MAIN_HOST;

  const BreadcrumbList = seo?.breadcrumbList?.map((seoItem) => {
    return {
      '@type': 'ListItem',
      position: seoItem.position,
      name: seoItem.name,
      item: seoItem.item,
    };
  });

  const FAQ = seo?.faq?.map((faqItem) => {
    return {
      '@type': 'Question',
      name: faqItem.name,

      acceptedAnswer: {
        '@type': 'Answer',
        text: faqItem.answer,
      },
    };
  });

  if (seo?.productСard) {
    productSchema = {
      '@context': 'http://schema.org/',
      '@type': 'Product',
      name: seo.productСard.product.name,
      image: seo.productСard.product.photos[0]?.source,
      description: seo.productСard.product.description,
      sku: seo.productСard.product.id,
      brand: {
        '@type': 'Brand',
        name: seo.productСard.product.store.name,
      },
      offers: {
        '@type': 'Offer',
        priceCurrency: 'RUB',
        price: seo.productСard.product.price,
        url: routes.productsSubCategory(
          seo.productСard.product.category.slug,
          seo.productСard.product.subcategory.slug,
        ),
        // availability: seo.productСard,product. откуда брать количество товара.
        // itemCondition: 'https://schema.org/NewCondition', не понял, спроси у SEO
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: seo.productСard.product.rating,
        ratingCount: seo.productСard.product.reviewsCount,
        // worstRating: на беке подправить не приходят значения,
        // bestRating: на беке подправить не приходят значения,
      },
      review: seo.productСard.reviews.map((review) => ({
        '@type': 'Review',
        name: review.heading,
        author: {
          '@type': 'Person',
          name: review.name,
        },
        datePublished: review.date,
        reviewBody: review.text,
        reviewRating: {
          '@type': 'Rating',
          ratingValue: review.star,
          //   worstRating: review.worstRating,
          //   bestRating: review.bestRating,
        },
      })),
    };
  }

  if (seo?.productsCatalog) {
    productCatalog = seo.productsCatalog.products.data.map((product) => {
      return {
        '@context': 'http://schema.org/',
        '@type': 'Product',
        name: product.name,
        image: product?.photos[0]?.source,
        description: product.description,
        sku: product.id,
        brand: {
          '@type': 'Brand',
          // name: product.,на беке пустой приходит
        },
        offers: {
          '@type': 'Offer',
          priceCurrency: 'RUB',
          price: product.price,
          url: routes.productsSubCategory(product.category.slug, product.subcategory.slug),
          // availability: product.availability,
          // itemCondition: 'https://schema.org/NewCondition',
        },
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: product.rating,
          ratingCount: product.reviewsCount,
          // worstRating: product.worstRating,
          // bestRating: product.bestRating,
        },
      };
    });
  }

  return (
    <Head>
      {seo?.OpenGraph && (
        <>
          <meta property="og:type" content="product" />
          <meta
            property="og:title"
            content={seoFormatter({
              text: seo?.productСard.product.name,
              name: seo?.name,
              subcategory: seo?.subcategory,
              category: seo?.category,
              manufacturer: seo?.manufacturer,
              price: seo?.price,
              annotate: seo?.annotate,
            })}
          />
          <meta
            property="og:description"
            content={seoFormatter({
              text: seo.productСard.product.description,
              name: seo?.name,
              subcategory: seo?.subcategory,
              category: seo?.category,
              manufacturer: seo?.manufacturer,
              price: seo?.price,
              annotate: seo?.annotate,
            })}
          />
          <meta property="og:url" content={`https://yfermera.ru${router.asPath}`} />
          <meta property="og:image" content={seo.productСard.product.photos[0]?.source} />
          <meta property="og:site_name" content="У фермера" />
          <meta property="og:locale" content="ru_RU" />
        </>
      )}
      {!seo?.OpenGraph && (
        <>
          <meta property="og:type" content="website" />
          <meta property="og:title" content="" />
          <meta property="og:description" content="" />
          <meta property="og:url" content="" />
          <meta property="og:image" content="" />
          <meta property="og:site_name" content="У фермера" />
          <meta property="og:locale" content="ru_RU" />
        </>
      )}
      {get(props.seo, 'metaTitle') ? (
        <title>
          {seoFormatter({
            text: props.seo.metaTitle,
            name: seo?.name,
            subcategory: seo?.subcategory,
            category: seo?.category,
            manufacturer: seo?.manufacturer,
            price: seo?.price,
            annotate: seo?.annotate,
          })}
        </title>
      ) : (
        <title>Уфермера</title>
      )}
      {get(props.seo, 'metaDescription') && (
        <meta
          name="description"
          content={seoFormatter({
            text: props?.seo?.metaDescription,
            name: seo?.name,
            subcategory: seo?.subcategory,
            category: seo?.category,
            manufacturer: seo?.manufacturer,
            price: seo?.price,
            annotate: seo?.annotate,
          })}
        />
      )}
      {get(props.seo, 'metaKeywords') && (
        <meta
          name="keywords"
          content={seoFormatter({
            text: props.seo.metaKeywords,
            name: seo?.name,
            subcategory: seo?.subcategory,
            category: seo?.category,
            manufacturer: seo?.manufacturer,
            price: seo?.price,
            annotate: seo?.annotate,
          })}
        />
      )}
      <link rel="canonical" href={mainHost}></link>
      <meta name="name" content="У Фермера"></meta>
      <meta name="url" content="https://yfermera.ru"></meta>
      <meta name="target" content={`https://yfermera.ru/${router.asPath}`}></meta>
      <meta name="query-input" content={router.asPath}></meta>

      {/* Organization */}
      <meta name="telephone" content="+79008007060"></meta>
      <meta name="alternateName" content='ООО "У Фермера"'></meta>
      <meta name="logo" content="https://yfermera.ru/icons2/header-logotip.svg"></meta>
      <meta name="contactType" content="customer service"></meta>
      <meta name="areaServed" content="RU"></meta>
      <meta name="availableLanguage" content="Russian"></meta>
      <meta name="sameAs" content='"https://yfermera.ru", "ТЕЛЕГА", "ВКОНТАКТЕ"'></meta>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

      {seo?.breadcrumbList && (
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: BreadcrumbList,
          })}
        </script>
      )}

      {seo?.faq && (
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            itemListElement: FAQ,
          })}
        </script>
      )}

      {seo?.productСard && (
        <script type="application/ld+json">{JSON.stringify(productSchema)}</script>
      )}
      {seo?.productCatalog && (
        <script type="application/ld+json">{JSON.stringify(productCatalog)}</script>
      )}
    </Head>
  );
};

SeoHead.defaultProps = {
  seo: null,
};

SeoHead.propTypes = {
  seo: PropTypes.shape(),
};

export default SeoHead;
